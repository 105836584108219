import { JsonObject, JsonProperty } from "json2typescript";
import { Result } from "domain/entities/result";

@JsonObject("Data")
class Data {
    @JsonProperty("IDs", [Number])
    ids: number[] = [];
}

@JsonObject("RequestBatchResult")
export class RequestBatchResult extends Result {
    @JsonProperty("data", Data)
    data?: Data;
}