import React, { FunctionComponent, useEffect, useState } from 'react';
import { FiRepeat } from "react-icons/fi";

import { Box, Button, Flex, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import ReactJoyride, { STATUS } from 'react-joyride';

import { IDrugInteractionBoxProps } from './IDrugInteractionBoxProps';
import { DrugName } from 'presentation/components/DrugName';
import { MobileSeverityHeader } from 'presentation/components/MobileSeverityHeader';
import { InteractionInfo } from 'presentation/components/InteractionInfo';
import { SeverityBadge } from 'presentation/components/SeverityBadge';
import { InfoModal } from './InfoModal';


export const DrugInteractionBox: FunctionComponent<IDrugInteractionBoxProps> = (props) => {

    const { isOpen, onToggle } = useDisclosure();
    const [openInfoDrug1, setOpenInfoDrug1] = useState(false);
    const [openInfoDrug2, setOpenInfoDrug2] = useState(false);
    const [openInfoDrug3, setOpenInfoDrug3] = useState(false);

    const [runTour, setRunTour] = useState(false);
    const [steps] = useState([
        {
            target: '.tour_drug_name_helper',
            content: (
                <div>
                    <p>Presionando sobre un médicamento puedes ver su información</p>
                </div>
            ),
            disableBeacon: true,
            spotlightPadding: 5,
        },
        {
            target: '.tour_drug_class_name_helper',
            content: (
                <div>
                    <p>Posando el puntero sobre una clase de médicamento puedes obtener su descripción</p>
                </div>
            ),
            disableBeacon: true,
            spotlightPadding: 5,
        },
    ]);

    useEffect(() => {
        if(props.tour && window.localStorage.getItem('ddi_tour_interaction_result_finish') === null) {
            setRunTour(true);
        }
    }, [props.tour]);

    function _getRelation(relation?: string): string {
        switch (relation) {
            case "impl": return " ⇒ ";
            case "ssi": return " ⇔ ";
            default: return " + ";
        }
    }

    function _getLinks(links?: string): any {
        return links?.split(" ") ?? [];
    }

    return (
        <Box bg="#ffffff" p={{ base: 5, md: 19 }} m="5px" marginBottom="20px" boxShadow="0 10px 10px -5px #ebebff" borderRadius="10px" border="1px" borderColor="#f7f7ff">
            {props.tour &&
                <ReactJoyride
                    steps={steps}
                    run={runTour}
                    continuous={true}
                    scrollToFirstStep={true}
                    locale={{ back: 'Atras', close: 'Cerrar', last: 'Lo entiendo!', next: 'Siguiente', skip: 'Saltar' }}
                    callback={({ status }) => {
                        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
                            window.localStorage.setItem('ddi_tour_interaction_result_finish', 'true');
                            setRunTour(false);
                        }
                    }}
                />
            }
            <MobileSeverityHeader
                severityName={props.interaction.severity?.name}
                severityDescription={props.interaction.severity?.description}
                onClickToggle={onToggle}
            />
            <Flex marginBottom={{ base: "15px", md: "30px" }}>
                <Box d="flex">
                    <DrugName
                        drugName={props.interaction.drug1?.name}
                        drugClass={props.interaction.drug1?.drugClass?.name}
                        classDescription={props.interaction.drug1?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug1(true)}
                    />

                    <Box>
                        <Heading as="h4" size="md" textAlign="center">{_getRelation(props.interaction.relation)}</Heading>
                    </Box>

                    <DrugName
                        drugName={props.interaction.drug2?.name}
                        drugClass={props.interaction.drug2?.drugClass?.name}
                        classDescription={props.interaction.drug2?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug2(true)}
                    />
                    {props.interaction.drug3 &&
                        <Box>
                            <Heading as="h4" size="md" textAlign="center">+</Heading>
                        </Box>
                    }
                    {props.interaction.drug3 &&
                        <DrugName
                            drugName={props.interaction.drug3.name}
                            drugClass={props.interaction.drug3.drugClass?.name}
                            classDescription={props.interaction.drug3.drugClass?.description}
                            onClickDrugName={() => setOpenInfoDrug3(true)}
                        />
                    }
                </Box>
                <Box display={{ base: 'none', md: 'block' }}>
                    <SeverityBadge
                        severityName={props.interaction.severity?.name}
                        severityDescripcion={props.interaction.severity?.description}
                    />
                </Box>
                <Spacer />
                <Box display={{ base: 'none', md: 'block' }}>
                    <Button
                        leftIcon={<FiRepeat />}
                        colorScheme="blue"
                        variant="outline"
                        onClick={onToggle}
                    >
                        {isOpen ? "Información general" : "Evidencia científica"}
                    </Button>
                </Box>
            </Flex>
            <InteractionInfo
                effect={props.interaction.effect}
                description={props.interaction.description}
                evidences={[props.interaction.evidence]}
                references={[
                    ..._getLinks(props.interaction.sources),
                    ..._getLinks(props.interaction.evidenceSource)
                ]}
                showTechInformation={isOpen}
            />
            {openInfoDrug1 &&
                <InfoModal
                    drug={props.interaction.drug1}
                    isOpen={openInfoDrug1}
                    onClose={() => setOpenInfoDrug1(false)}
                />
            }
            {openInfoDrug2 &&
                <InfoModal
                    drug={props.interaction.drug2}
                    isOpen={openInfoDrug2}
                    onClose={() => setOpenInfoDrug2(false)}
                />
            }
            {openInfoDrug3 &&
                <InfoModal
                    drug={props.interaction.drug3}
                    isOpen={openInfoDrug3}
                    onClose={() => setOpenInfoDrug3(false)}
                />
            }
        </Box>
    );
}