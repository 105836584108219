import React, { FunctionComponent } from 'react';

import { IVideoModalProps } from './IVideoModalProps';
import { CustomModal } from '../CustomModal';

export const VideoModal: FunctionComponent<IVideoModalProps> = (props) => {
    return (
        <CustomModal
            title={props.title}
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            <video width="auto" height="auto" controls autoPlay>
                <source src={props.videoUrl} type="video/mp4"/>
            </video>
        </CustomModal>
    );
}