import { DrugDrugInteractionResult, DrugDrugInteraction, RequestResult, RequestBatchResult } from "domain/entities/drug-drug-interaction";
import { Result } from "domain/entities/result";
import { DrugDrugInteractionRepository } from "domain/repositories";
import Base from "data/base";

export class DrugDrugInteractionApi implements DrugDrugInteractionRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/interactions/drug-drug";
    }

    /**
     * Obtiene todas las interacciones droga-droga
     * @param token
     * @returns retorna un array con las interacciones droga-droga
     */
    async getAllDrugDrugInteraction(token: string): Promise<[DrugDrugInteractionResult[], null] | [null, Error]> {
        try {
            const url: string = await DrugDrugInteractionApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DrugDrugInteractionResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una interacción droga-droga por su ID
     * @param id 
     * @returns retorna una interacción droga-droga
     */
    async getDrugDrugInteraction(id: number): Promise<[null, Error] | [DrugDrugInteractionResult, null]> {
        try {
            const url: string = await DrugDrugInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DrugDrugInteractionResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una interaccion droga-droga
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugDrugInteraction(interaction: DrugDrugInteraction, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await DrugDrugInteractionApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dru_id1: interaction.drugId1,
                dru_id2: interaction.drugId2,
                dru_id3: interaction.drugId3,
                dse_id: interaction.severityId,
                dty_id: interaction.methodId,
                din_relation: interaction.relation,
                din_effect: interaction.effect,
                din_description: interaction.description,
                din_evidence: interaction.evidence,
                din_evidence_source: interaction.evidenceSource,
                din_source: interaction.sources,
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un lote de interacciones droga-droga
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createDrugDrugInteractionBatch(interactions: DrugDrugInteraction[], token: string): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await DrugDrugInteractionApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: interactions.map(i => ({
                    dru_id1: i.drugId1,
                    dru_id2: i.drugId2,
                    dru_id3: i.drugId3,
                    dse_id: i.severityId,
                    dty_id: i.methodId,
                    din_relation: i.relation,
                    din_effect: i.effect,
                    din_description: i.description,
                    din_evidence: i.evidence,
                    din_evidence_source: i.evidenceSource,
                    din_source: i.sources,
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una interacción droga-droga
     * @param id 
     * @param interaction 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateDrugDrugInteraction(id: number, interaction: DrugDrugInteraction, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugDrugInteractionApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dru_id1: interaction.drugId1,
                dru_id2: interaction.drugId2,
                dru_id3: interaction.drugId3,
                dse_id: interaction.severityId,
                dty_id: interaction.methodId,
                din_relation: interaction.relation,
                din_effect: interaction.effect,
                din_description: interaction.description,
                din_evidence: interaction.evidence,
                din_evidence_source: interaction.evidenceSource,
                din_source: interaction.sources,
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una interacción droga-droga
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteDrugDrugInteraction(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await DrugDrugInteractionApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

}