import { useCallback, useState } from "react";

import { DrugApi } from "data";
import { Drug, DrugResult } from "domain/entities/drug";
import GetAllUseCase from "domain/use-cases/drug/GetAllUseCase";
import CreateUseCase from "domain/use-cases/drug/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/drug/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/drug/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/drug/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todas las drogas registrados.
const _getAllDrugs = () => {
    const drugRepository = new DrugApi();
    const getAllUseCase = new GetAllUseCase(drugRepository);

    return getAllUseCase.getAllDrug();
};

// Retorna una promesa para agregar una droga desde la API.
const _createDrug = (drug: Drug, token: TokenPromise) => {
    const drugRepository = new DrugApi();
    const createUseCase = new CreateUseCase(drugRepository);

    return createUseCase.createDrug(drug, token);
};

// Retorna una promesa para agregar un lote de drogas desde la API.
const _createDrugBatch = (drugs: Drug[], token: TokenPromise) => {
    const drugRepository = new DrugApi();
    const createBatchUseCase = new CreateBatchUseCase(drugRepository);

    return createBatchUseCase.createDrugBatch(drugs, token);
};

// Retorna una promesa para editar una droga desde la API.
const _editDrug = (drugId: number, drug: Drug, token: TokenPromise) => {
    const drugRepository = new DrugApi();
    const updateUseCase = new UpdateUseCase(drugRepository);

    return updateUseCase.updateDrug(drugId, drug, token);
};

// Retorna una promesa para eliminar desde la API una droga por su ID.
const _deleteDrug = (drugId: number, token: TokenPromise) => {
    const drugRepository = new DrugApi();
    const deleteUseCase = new DeleteUseCase(drugRepository);

    return deleteUseCase.deleteDrug(drugId, token);
};


export const useDrugs = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<DrugResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas las drigas registradas.
    const getAllDrugs = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllDrugs().then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, []);

    // Guarda una droga desde API
    const saveDrug = useCallback((drug: DrugResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDrug(drug, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                drug.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], drug]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Guarda un lote de drogas desde API
    const saveDrugBatch = useCallback((drugs: DrugResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDrugBatch(drugs, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllDrugs();
            }
        });
    }, [getAllDrugs, getTokens, isUpdateStateOk]);

    // Edita una droga desde API
    const editDrug = useCallback((drug: DrugResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editDrug(drug.id, drug, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === drug.id ? drug : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una droga del estado
    const deleteDrug = useCallback((drugId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDrug(drugId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== drugId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllDrugs, saveDrug, saveDrugBatch, editDrug, deleteDrug, state, data };
};