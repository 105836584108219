import { useCallback, useState } from "react";

import { DiseaseApi } from "data";
import { Disease, DiseaseResult } from "domain/entities/disease";
import GetAllUseCase from "domain/use-cases/disease/GetAllUseCase";
import CreateUseCase from "domain/use-cases/disease/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/disease/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/disease/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/disease/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los usuarios registrados.
const _getAllDiseases = () => {
    const diseaseRepository = new DiseaseApi();
    const getAllUseCase = new GetAllUseCase(diseaseRepository);

    return getAllUseCase.getAllDisease();
};

// Retorna una promesa para agregar una enfermedad desde la API.
const _createDisease = (disease: Disease, token: TokenPromise) => {
    const diseaseRepository = new DiseaseApi();
    const createUseCase = new CreateUseCase(diseaseRepository);

    return createUseCase.createDisease(disease, token);
};

// Retorna una promesa para agregar un lote de enfermedades desde la API.
const _createDiseaseBatch = (diseases: Disease[], token: TokenPromise) => {
    const diseaseRepository = new DiseaseApi();
    const createBatchUseCase = new CreateBatchUseCase(diseaseRepository);

    return createBatchUseCase.createDiseaseBatch(diseases, token);
};

// Retorna una promesa para editar una enfermedad desde la API.
const _editDisease = (diseaseId: number, disease: Disease, token: TokenPromise) => {
    const diseaseRepository = new DiseaseApi();
    const updateUseCase = new UpdateUseCase(diseaseRepository);

    return updateUseCase.updateDisease(diseaseId, disease, token);
};

// Retorna una promesa para eliminar desde la API una enfermedad por su ID.
const _deleteDisease = (diseaseId: number, token: TokenPromise) => {
    const diseaseRepository = new DiseaseApi();
    const deleteUseCase = new DeleteUseCase(diseaseRepository);

    return deleteUseCase.deleteDisease(diseaseId, token);
};

// Hook usado para gestionar el CRUD de enfermedades.
export const useDiseases = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<DiseaseResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas las enfermedades registradas.
    const getAllDiseases = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllDiseases().then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, []);

    // Guarda una enfermedad desde API
    const saveDisease = useCallback((disease: DiseaseResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDisease(disease, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                disease.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], disease]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Guarda un lote de enfermedades desde API
    const saveDiseaseBatch = useCallback((diseases: DiseaseResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDiseaseBatch(diseases, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllDiseases();
            }
        });
    }, [getAllDiseases, getTokens, isUpdateStateOk]);

    // Edita una enfermedad desde API
    const editDisease = useCallback((disease: DiseaseResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editDisease(disease.id, disease, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === disease.id ? disease : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una enfermedad del estado
    const deleteDisease = useCallback((diseaseId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDisease(diseaseId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== diseaseId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllDiseases, saveDisease, saveDiseaseBatch, editDisease, deleteDisease, state, data };
};