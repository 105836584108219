import { LoginResult } from "domain/entities/user";
import { UserRepository } from "domain/repositories";

export default class TokenUpdateUseCase {
    private userRepository: UserRepository;
    
    public constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    public async updateToken(refreshToken: string): Promise<([LoginResult, null]) | ([null, Error])> {
        return this.userRepository.updateToken(refreshToken);
    }
}