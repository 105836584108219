import React, { FunctionComponent, useState } from 'react';

import { Box, Button, Flex, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import { FiRepeat } from "react-icons/fi";

import { DrugName } from 'presentation/components/DrugName';
import { MobileSeverityHeader } from 'presentation/components/MobileSeverityHeader';
import { InteractionInfo } from 'presentation/components/InteractionInfo';
import { SeverityBadge } from 'presentation/components/SeverityBadge';
import { IDiseaseInteractionBoxProps } from './IDiseaseInteractionBoxProps';
import { InfoModal as DrugInfoModal } from '../DrugInteractionBox/InfoModal';
import { InfoModal as DiseaseInfoModal } from './InfoModal';


export const DiseaseInteractionBox: FunctionComponent<IDiseaseInteractionBoxProps> = (props) => {

    const { isOpen, onToggle } = useDisclosure();
    const [openInfoDrug, setOpenInfoDrug] = useState(false);
    const [openInfoDisease, setOpenInfoDisease] = useState(false);

    function _getLinks(links?: string): any {
        return links?.split(" ") ?? [];
    }

    return (
        <Box bg="#ffffff" p={{base: 5, md: 19}} m="5px" marginBottom="20px" boxShadow="0 10px 10px -5px #ebebff" borderRadius="10px" border="1px" borderColor="#f7f7ff">
            <MobileSeverityHeader
                severityName={props.interaction.severity?.name}
                severityDescription={props.interaction.severity?.description}
                onClickToggle={onToggle}
            />
            <Flex marginBottom={{base: "15px", md: "30px"}}>
                <Box d="flex">
                    <DrugName
                        drugName={props.interaction.drug?.name}
                        drugClass={props.interaction.drug?.drugClass?.name}
                        classDescription={props.interaction.drug?.drugClass?.description}
                        onClickDrugName={() => setOpenInfoDrug(true)}
                    />

                    <Box>
                        <Heading as="h4" size="md" textAlign="center">+</Heading>
                    </Box>

                    <Heading as="h4" size="md" textAlign="center" cursor="pointer" onClick={() => setOpenInfoDisease(true)}>
                        {props.interaction.disease?.name}
                    </Heading>
                </Box>
                <Box display={{ base: 'none', md: 'block' }}>
                    <SeverityBadge
                        severityName={props.interaction.severity?.name}
                        severityDescripcion={props.interaction.severity?.description}
                    />
                </Box>
                <Spacer />
                <Box display={{ base: 'none', md: 'block' }}>
                    <Button
                        leftIcon={<FiRepeat />}
                        colorScheme="blue"
                        variant="outline"
                        onClick={onToggle}
                    >
                        Información técnica
                </Button>
                </Box>
            </Flex>
            <InteractionInfo
                effect={props.interaction.effect}
                description={props.interaction.description}
                evidences={[props.interaction.evidence]}
                references={[
                    ..._getLinks(props.interaction.sources),
                    ..._getLinks(props.interaction.evidenceSource)
                ]}
                showTechInformation={isOpen}
            />
            {openInfoDrug &&
                <DrugInfoModal
                    drug={props.interaction.drug}
                    isOpen={openInfoDrug}
                    onClose={() => setOpenInfoDrug(false)}
                />
            }
            {openInfoDisease &&
                <DiseaseInfoModal
                    disease={props.interaction.disease}
                    isOpen={openInfoDisease}
                    onClose={() => setOpenInfoDisease(false)}
                />
            }
        </Box>
    );
}