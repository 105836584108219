import { Result } from "domain/entities/result";
import { UserResult, User, RequestResult } from "domain/entities/user";
import { LoginResult } from "domain/entities/user/structures/LoginResult";
import { UserRepository } from "domain/repositories";
import Base from "data/base";

export class UserApi implements UserRepository {
    
    private static async rute0(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/users";
    }

    private static async rute1(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/login";
    }

    private static async rute2(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/token";
    }

    private static async rute4(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/resetPassword";
    }

    /**
     * Obtiene todos los usuarios
     * @returns retorna un array con los usuarios
     */
    async getAllUsers(token: string): Promise<[UserResult[], null] | [null, Error]> {
        try {
            const url: string = await UserApi.rute0() + "/";
            
            const [res, error] = await Base.getArray(url, UserResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtener usuario por ID
     * @param id 
     * @param token 
     * @returns retorno de usuario
     */
    async getUser(id: number, token: string): Promise<[null, Error] | [UserResult, null]> {
        try {
            const url: string = await UserApi.rute0() + "/" + id;
                
            const [res, error] = await Base.get(url, UserResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un usuario
     * @param user 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createUser(user: User, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await UserApi.rute0() + "/";
            const [res, error] = await Base.post(url, {
                usr_email: user.email,
                usr_name: user.name,
                usr_password: user.password,
                rol_id: user.rolId, 
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza un usuario por su ID
     * @param id 
     * @param user 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateUser(id: number, user: User, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await UserApi.rute0() + "/" + id;
            const [res, error] = await Base.put(url, {
                usr_email: user.email,
                usr_name: user.name,
                usr_password: user.password !== "" ? user.password : undefined,
                rol_id: user.rolId,
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
    /**
     * Elimina un usuario por su ID
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteUser(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await UserApi.rute0() + "/" + id;
                
            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Iniciar sesión
     * @param email 
     * @param password 
     * @returns Retorna resultado de inicio de sesión (token y tokenRefresh)
     */
    async login(email: string, password: string): Promise<[null, Error] | [LoginResult, null]> {
        try {
            const url: string = await UserApi.rute1() + "/";

            const [res, error] = await Base.post(url, {
                usr_email: email,
                usr_password: password
            }, LoginResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Recuperar contraseña
     * @param email 
     * @returns Retorna resultado de recuperar contraseña (token y tokenRefresh)
     */
     async resetPassword(email: string): Promise<[null, Error] | [LoginResult, null]> {
        try {
            const url: string = await UserApi.rute4() + "/";

            const [res, error] = await Base.post(url, {
                usr_email: email
            }, LoginResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza accessToken
     * @param refreshToken 
     * @returns retorna resultado de actualización (token y refreshToken)
     */
    async updateToken(refreshToken: string): Promise<[LoginResult, null] | [null, Error]> {
        try {
            const url: string = await UserApi.rute2() + "/refresh";

            const [res, error] = await Base.post(url, {
                refresh_token: refreshToken
            }, LoginResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }
}