import React, { FunctionComponent } from 'react'

import { Box, Heading, Popover, PopoverArrow, PopoverContent, PopoverCloseButton, PopoverBody, PopoverTrigger, Text } from '@chakra-ui/react';

import { IDrugNameProps } from './IDrugNameProps'

export const DrugName: FunctionComponent<IDrugNameProps> = (props) => {
    return (
        <Box marginLeft="5px" marginRight="5px">
            <Heading className="tour_drug_name_helper" as="h4" size="md" textAlign="center" cursor={props.onClickDrugName ? "pointer" : "default"} onClick={() => props.onClickDrugName ? props.onClickDrugName() : {}}>
                {props.drugName}
            </Heading>

            <Popover trigger="hover">
                <PopoverTrigger>
                    <Text className="tour_drug_class_name_helper" fontSize="xs" align="center" maxWidth="200px">{props.drugClass}</Text>
                </PopoverTrigger>
                <PopoverContent bg="blue.50">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody paddingTop="20px">
                        <Text fontSize="sm">{props.classDescription}</Text>
                    </PopoverBody>
                </PopoverContent>
            </Popover>

        </Box>
    );
}