import React, { FunctionComponent } from 'react';
import { FiRepeat } from 'react-icons/fi';

import { Box, Flex, IconButton, Spacer } from '@chakra-ui/react';

import { MobileSeverityHeaderProps } from './MobileSeverityHeaderProps';
import { SeverityBadge } from 'presentation/components/SeverityBadge';

export const MobileSeverityHeader: FunctionComponent<MobileSeverityHeaderProps> = (props) => {

    return (
        <Flex marginBottom="5px">
            <Box display={{ base: 'block', md: 'none' }}>
                <SeverityBadge
                    severityName={props.severityName}
                    severityDescripcion={props.severityDescription}
                />
            </Box>
            <Spacer />
            <Box display={{ base: 'block', md: 'none' }}>
                <IconButton
                    colorScheme="blue"
                    aria-label="Search database"
                    icon={<FiRepeat />}
                    onClick={() => { props.onClickToggle() }}
                />
            </Box>
        </Flex>
    );
}