import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { PermissionResult } from "./PermissionResult";

@JsonObject("RoleResult")
export class RoleResult {
    @JsonProperty("rol_id", Number)
    id: number = 0;
    @JsonProperty("rol_name", String)
    name: string = "";
    @JsonProperty("rol_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("rol_updated_at", DateConverter)
    updatedAt: Date = new Date();
    @JsonProperty("Permissions", [PermissionResult])
    permissions: PermissionResult[] = [];
}