import { DiseaseResult } from "domain/entities/disease";
import { DiseaseRepository } from "domain/repositories";

export default class GetAllUseCase {
    private diseaseRepository: DiseaseRepository;
    
    public constructor(diseaseRepository: DiseaseRepository) {
        this.diseaseRepository = diseaseRepository
    }

    public async getAllDisease(): Promise<[DiseaseResult[], null] | [null, Error]> {
        return this.diseaseRepository.getAllDisease();
    }
}