import React, { FunctionComponent } from 'react';

import { Box, Center, Flex, Heading } from '@chakra-ui/react';

import { CustomModal } from 'presentation/components/CustomModal';
import { ElementInfo } from 'presentation/components/ElementInfo';
import { IInfoModalProps } from './IInfoModalProps';

import { ReactComponent as DiseaseIcon } from "./disease-icon.svg";

export const InfoModal: FunctionComponent<IInfoModalProps> = (props) => {

    function _getLinks(links?: string): any {
        return links?.split(" ") ?? [];
    }

    return (
        <CustomModal
            title="Información de enfermedad"
            isOpen={props.isOpen}
            onClose={props.onClose}
        >
            { props.disease &&
                <Box bg="#ffffff" p={{ base: "15px", md: "20px" }}>
                    <Flex marginBottom="10px">
                        <Box d="flex">
                            <DiseaseIcon width="35px" />
                        </Box>
                        <Box d="flex">
                            <Heading as="h4" size="md" textAlign="center">
                                {props.disease.name}
                            </Heading>
                        </Box>
                    </Flex>
                    <ElementInfo
                        description={props.disease.description}
                        sources={_getLinks(props.disease.sources)}
                        showTechInformation={false}
                    />
                </Box>
            }
            {!props.disease &&
                <Center>
                    Sin información disponible
                </Center>
            }
        </CustomModal>
    );
}