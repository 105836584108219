import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { DrugResult } from "domain/entities/drug/structures/DrugResult";
import { FoodResult } from "domain/entities/food";
import { MethodResult } from "domain/entities/method";
import { SeverityResult } from "domain/entities/severity";
import { UserResult } from "domain/entities/user";

@JsonObject("DrugFoodInteractionResult")
export class DrugFoodInteractionResult {
    @JsonProperty("dfi_id", Number)
    id: number = 0;
    @JsonProperty("dru_id", Number)
    drugId: number = 0;
    @JsonProperty("Drug", DrugResult)
    drug?: DrugResult;
    @JsonProperty("foo_id", Number)
    foodId: number = 0;
    @JsonProperty("Food", FoodResult)
    food?: FoodResult;
    @JsonProperty("dse_id", Number)
    severityId: number = 0;
    @JsonProperty("DDISeverity", SeverityResult)
    severity?: SeverityResult;
    @JsonProperty("dty_id", Number)
    methodId: number = 0;
    @JsonProperty("DDIType", MethodResult)
    method?: MethodResult;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("dfi_effect", String)
    effect: string = "";
    @JsonProperty("dfi_description", String)
    description: string = "";
    @JsonProperty("dfi_evidence", String)
    evidence: string = "";
    @JsonProperty("dfi_evidence_source", String)
    evidenceSource: string = "";
    @JsonProperty("dfi_source", String)
    sources: string = "";
    @JsonProperty("dfi_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("dfi_updated_at", DateConverter)
    updatedAt: Date = new Date();
}