import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript";

export default class Base {

    private static async getError(res: any): Promise<[boolean, Error] | [boolean, null]> {
        try {
            if (res.ok) return [false, null];

            const jsonData = await res.json();
            if (jsonData.status !== undefined) {
                return [true, new Error(jsonData.message)];
            } else {
                if (res.status === 401) return [true, new Error("Autenticación faltante")];
                else return [true, new Error("Error desconocido")];
            }
        } catch (e) {
            console.log(e);
            return [true, new Error("Error desconocido")];
        }
    }

    static async getArray<T>(url: string, classReference: { new(): T; }, token?: string): Promise<([T[], null]) | ([null, Error])> {
        try {
            const res = token === undefined
                ? await fetch(url, {
                    method: 'GET',
                })
                : await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
            //const res = await fetch(url);
            const [hasError, error] = await this.getError(res);
            if (hasError && error !== null) {
                return [null, error];
            }

            const jsonData = await res.json();

            let jsonConvert: JsonConvert = new JsonConvert();
            jsonConvert.operationMode = OperationMode.ENABLE;
            jsonConvert.ignorePrimitiveChecks = false;
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

            try {
                if (Array.isArray(jsonData)) {
                    let result: T[];
                    result = jsonData.map(item => jsonConvert.deserializeObject(item, classReference));
                    return [result, null];
                } else {
                    if (jsonData !== null) {
                        let result: T;
                        result = jsonConvert.deserializeObject(jsonData, classReference);
                        return [[result], null];
                    } else {
                        return [[], null];
                    }
                }
            } catch (e) {
                console.log(e);
                return [null, new Error("Error al procesar elementos")];
            }
        } catch (e) {
            console.log(e);
            return [null, new Error("Error al procesar elementos")];
        }
    }

    static async get<T>(url: string, classReference: { new(): T; }, token?: string): Promise<([T, null]) | ([null, Error])> {
        try {
            const res = token === undefined
                ? await fetch(url, {
                    method: 'GET',
                })
                : await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
            //const res = await fetch(url);
            const [hasError, error] = await this.getError(res);
            if (hasError && error !== null) {
                return [null, error];
            }

            const jsonData = await res.json();

            let jsonConvert: JsonConvert = new JsonConvert();
            jsonConvert.operationMode = OperationMode.ENABLE;
            jsonConvert.ignorePrimitiveChecks = false;

            try {
                if (!Array.isArray(jsonData)) {
                    let result: T;
                    result = jsonConvert.deserializeObject(jsonData, classReference);
                    return [result, null];
                } else {
                    return [null, new Error("Existe más de un elemento")]
                }
            } catch (e) {
                console.log(e);
                return [null, new Error("Error al procesar elementos")];
            }
        } catch (e) {
            console.log(e);
            return [null, new Error("Error al procesar elementos")];
        }
    }

    static async post<T>(url: string, body: any, classReference: { new(): T; }, token?: string): Promise<([T, null]) | ([null, Error])> {
        try {
            const res = token === undefined
                ? await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
                : await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(body),
                });
            const [hasError, error] = await this.getError(res);
            if (hasError && error !== null) {
                return [null, error];
            }

            const jsonData: T = await res.json();

            let jsonConvert: JsonConvert = new JsonConvert();
            jsonConvert.operationMode = OperationMode.ENABLE;
            jsonConvert.ignorePrimitiveChecks = false;
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

            try {
                if (!Array.isArray(jsonData)) {
                    let result: T;
                    result = jsonConvert.deserializeObject(jsonData, classReference);
                    return [result, null];
                } else {
                    return [null, new Error("Existe más de un elemento")];
                }
            } catch (e) {
                console.log(e);
                return [null, new Error("Error al procesar elementos")];
            }

        } catch (e) {
            console.log(e);
            return [null, new Error("Error en petición")];
        }
    }

    static async put<T>(url: string, body: any, classReference: { new(): T; }, token?: string): Promise<([T, null]) | ([null, Error])> {
        try {
            const res = token === undefined
                ? await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
                : await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(body),
                });
            const [hasError, error] = await this.getError(res);
            if (hasError && error !== null) {
                return [null, error];
            }

            const jsonData: T = await res.json();

            let jsonConvert: JsonConvert = new JsonConvert();
            jsonConvert.operationMode = OperationMode.ENABLE;
            jsonConvert.ignorePrimitiveChecks = false;
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

            try {
                if (!Array.isArray(jsonData)) {
                    let result: T;
                    result = jsonConvert.deserializeObject(jsonData, classReference);
                    return [result, null];
                } else {
                    return [null, new Error("Existe más de un elemento")];
                }
            } catch (e) {
                console.log(e);
                return [null, new Error("Error al procesar elementos")];
            }

        } catch (e) {
            console.log(e);
            return [null, new Error("Error en petición")];
        }
    }

    static async delete<T>(url: string, classReference: { new(): T; }, token?: string): Promise<([T, null]) | ([null, Error])> {
        try {
            const res = token === undefined
                ? await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                : await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

            const [hasError, error] = await this.getError(res);
            if (hasError && error !== null) {
                return [null, error];
            }

            const jsonData: T = await res.json();

            let jsonConvert: JsonConvert = new JsonConvert();
            jsonConvert.operationMode = OperationMode.ENABLE;
            jsonConvert.ignorePrimitiveChecks = false;
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

            try {
                if (!Array.isArray(jsonData)) {
                    let result: T;
                    result = jsonConvert.deserializeObject(jsonData, classReference);
                    return [result, null];
                } else {
                    return [null, new Error("Existe más de un elemento")];
                }
            } catch (e) {
                console.log(e);
                return [null, new Error("Error al procesar elementos")];
            }

        } catch (e) {
            console.log(e);
            return [null, new Error("Error en petición")];
        }
    }

    public static async getConfig(): Promise<any> {
        try {
            const res = await fetch('/apiConfig.json',
            {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );
            if (res.ok) return res.json();
            else return { DDI_API_URL: "" };
        } catch (e) {
            return { DDI_API_URL: "" };
        }
    }
}