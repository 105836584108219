import { Comment, RequestResult } from "domain/entities/comment";
import { CommentRepository } from "domain/repositories";

export default class CreateUseCase {
    private commentRepository: CommentRepository;
    
    public constructor(commentRepository: CommentRepository) {
        this.commentRepository = commentRepository
    }

    public async createComment(comment: Comment, token: Promise<[string | null, string | null]>): Promise<[RequestResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.commentRepository.createComment(comment, t);
        } else {
            return [null, new Error("Autenticación faltante")]
        }
    }
}
