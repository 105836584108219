import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ModuleResult")
export class ModuleResult {
    @JsonProperty("mod_id", Number)
    id: number = 0;
    @JsonProperty("mod_name", String)
    name: string = "";
    @JsonProperty("mod_code", String)
    code: string = "";
}