import { useCallback, useState } from "react";

import { UserApi } from "data";
import LoginUseCase from "domain/use-cases/user/LoginUseCase";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";
import { IUser } from "presentation/utils/interfaces/IUser";
import JwtManager from "presentation/utils/auth/JwtManager";

// Inicia sesión en la API y retorna las credenciales
const _login = (email: string, password: string) => {
    const userRepository = new UserApi();
    const loginUseCase = new LoginUseCase(userRepository);
    return loginUseCase.login(email, password);
};

/// Hook usado para iniciar sesión
export const useLogin = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult<IUser>>({
        loading: false,
        error: undefined,
        data: undefined,
    });

    // Inicia sesión con un email y una contraseña
    const login = useCallback((email: string, password: string) => {
        setState({ loading: true});
        _login(email, password).then(([res, error]) => {
            setState({
                loading: false,
                error: error?.message,
                data: res && res?.data !== (null || undefined) ? {
                    token: res.data.accessToken,
                    refreshToken: res.data.refreshToken,
                    data: JwtManager.getUserTokenInformation(res.data.accessToken),
                } : undefined,
            });
        });
    }, []);

    return { login, state };
};