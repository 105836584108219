import { Method } from "domain/entities/method";
import { Result } from "domain/entities/result";
import { MethodRepository } from "domain/repositories";

export default class UpdateUseCase {
    private methodRepository: MethodRepository;
    
    public constructor(methodRepository: MethodRepository) {
        this.methodRepository = methodRepository
    }

    public async updateMethod(id: number, method: Method, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.methodRepository.updateMethod(id, method, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}