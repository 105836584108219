import { Result } from "domain/entities/result";
import { RoleResult, Role, RequestResult } from "domain/entities/role";
import { RoleRepository } from "domain/repositories";
import Base from "data/base";

export class RoleApi implements RoleRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/roles";
    }

    /**
     * Obtiene todos los roles
     * @returns retorna un array con los roles
     */
    async getAllRoles(): Promise<[null, Error] | [RoleResult[], null]> {
        try {
            const url: string = await RoleApi.rute() + "/";

            const [res, error] = await Base.getArray(url, RoleResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene un rol por su ID
     * @param id 
     * @returns retorna rol
     */
    async getRole(id: number): Promise<[null, Error] | [RoleResult, null]> {
        try {
            const url: string = await RoleApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, RoleResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un rol
     * @param role 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createRole(role: Role, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await RoleApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                rol_name: role.name,
                rol_permissions: role.permissions.map(p => ({
                    mod_id: p.moduleId,
                    per_permission: p.permission,
                })),
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza un rol
     * @param id 
     * @param role 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateRole(id: number, role: Role, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await RoleApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                rol_name: role.name,
                rol_permissions: role.permissions.map(p => ({
                    mod_id: p.moduleId,
                    per_permission: p.permission,
                })),
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

}