import { DiseaseResult, Disease, RequestResult, RequestBatchResult } from "domain/entities/disease";
import { Result } from "domain/entities/result";
import { DiseaseRepository } from "domain/repositories";
import Base from "data/base";

export class DiseaseApi implements DiseaseRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/diseases";
    }

    /**
     * Obtiene todas las enfermedades
     * @returns Retorna un array con las enfermedades
     */
    async getAllDisease(): Promise<[DiseaseResult[], null] | [null, Error]> {
        try {
            const url: string = await DiseaseApi.rute() + "/";

            const [res, error] = await Base.getArray(url, DiseaseResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una enfermedad por su ID
     * @param id
     * @returns Retorna una enfermedad
     */
    async getDisease(id: number): Promise<[null, Error] | [DiseaseResult, null]> {
        try {
            const url: string = await DiseaseApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, DiseaseResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una enfermedad
     * @param disease 
     * @param token 
     * @returns Retorna el resultado de la creación 
     */
    async createDisease(disease: Disease, token: string): Promise<[RequestResult, null] | [null, Error]> {
        try {
            const url: string = await DiseaseApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dis_name: disease.name,
                dis_description: disease.description,
                dis_sources: disease.sources
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea enfermedades en lote
     * @param diseases 
     * @param token 
     * @returns Retorna el resultado de la creación 
     */
    async createDiseaseBatch(diseases: Disease[], token: string): Promise<[RequestBatchResult, null] | [null, Error]> {
        try {
            const url: string = await DiseaseApi.rute() + "/batch";
            const [res, error] = await Base.post(url, {
                batch: diseases.map(d => ({
                    dis_name: d.name,
                    dis_description: d.description,
                    dis_sources: d.sources
                }))
            }, RequestBatchResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualizar enfermedad
     * @param id 
     * @param disease 
     * @param token 
     * @returns Retorna el resultado de la actualización
     */
    async updateDisease(id: number, disease: Disease, token: string): Promise<[Result, null] | [null, Error]> {
        try {
            const url: string = await DiseaseApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dis_name: disease.name,
                dis_description: disease.description,
                dis_sources: disease.sources
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Eliminar enfermedad
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación
     */
    async deleteDisease(id: number, token: string): Promise<[Result, null] | [null, Error]> {
        try {
            const url: string = await DiseaseApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}