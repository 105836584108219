import React, { FunctionComponent } from 'react';

import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { v4 as uuid_v4 } from 'uuid';

import { IReferenceListProps } from './IReferenceListProps';

export const ReferenceList: FunctionComponent<IReferenceListProps> = (props) => {

    function _getLinks(linkList: string[]): any {
        return linkList.map((link: string) => {
            return <ListItem key={uuid_v4()}><Link color="teal.500" href={link}><Text fontSize="xs" isTruncated>{link}</Text></Link></ListItem>;
        });
    }

    return (
        <>
            { props.references.length > 0 && props.references[0] !== null &&
                <Box marginTop="5px" marginBottom="5px" maxWidth="100%">
                    <Text
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize="sm"
                        letterSpacing="wide"
                        color="gray.600"
                    >
                        Referencias
                    </Text>
                    <UnorderedList>{_getLinks(props.references)}</UnorderedList>
                </Box>
            }
        </>
    );
}