import { useCallback, useState } from "react";

import { SeverityApi } from "data";
import { Severity, SeverityResult } from "domain/entities/severity";
import GetAllUseCase from "domain/use-cases/severity/GetAllUseCase";
import CreateUseCase from "domain/use-cases/severity/CreateUseCase";
import UpdateUseCase from "domain/use-cases/severity/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/severity/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todas las severidades registrados.
const _getAllSeverities = (token: TokenPromise) => {
    const severityRepository = new SeverityApi();
    const getAllUseCase = new GetAllUseCase(severityRepository);

    return getAllUseCase.getAllSeverities(token);
};

// Retorna una promesa para agregar una severidad desde la API.
const _createSeverity = (severity: Severity, token: TokenPromise) => {
    const severityRepository = new SeverityApi();
    const createUseCase = new CreateUseCase(severityRepository);

    return createUseCase.createSeverity(severity, token);
};

// Retorna una promesa para editar una severidad desde la API.
const _editSeverity = (severityId: number, severity: Severity, token: TokenPromise) => {
    const severityRepository = new SeverityApi();
    const updateUseCase = new UpdateUseCase(severityRepository);

    return updateUseCase.updateSeverity(severityId, severity, token);
};

// Retorna una promesa para eliminar desde la API una severidad por su ID.
const _deleteSeverity = (severityId: number, token: TokenPromise) => {
    const severityRepository = new SeverityApi();
    const deleteUseCase = new DeleteUseCase(severityRepository);

    return deleteUseCase.deleteSeverity(severityId, token);
};

// Hook usado para gestionar el CRUD de severidades.
export const useSeverities = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<SeverityResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas las severidades registradas.
    const getAllSeverities = useCallback(() => {
        setState({ dataLoading: true, loading: false });
        _getAllSeverities(getTokens()).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, [getTokens]);

    // Guarda una severidad desde API
    const saveSeverity = useCallback((severity: SeverityResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createSeverity(severity, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                severity.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], severity]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Edita una severidad desde API
    const editSeverity = useCallback((severity: SeverityResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editSeverity(severity.id, severity, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === severity.id ? severity : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una severidad del estado
    const deleteSeverity = useCallback((severityId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteSeverity(severityId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== severityId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllSeverities, saveSeverity, editSeverity, deleteSeverity, state, data };
};