import { useCallback, useState } from "react";

import { DrugDrugInteractionApi } from "data";
import { DrugDrugInteraction, DrugDrugInteractionResult } from "domain/entities/drug-drug-interaction";
import GetAllUseCase from "domain/use-cases/drug-drug-interaction/GetAllUseCase";
import CreateUseCase from "domain/use-cases/drug-drug-interaction/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/drug-drug-interaction/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/drug-drug-interaction/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/drug-drug-interaction/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todas las interacciones drogas-droga registrados.
const _getAllDrugDrugInteractions = (token: TokenPromise) => {
    const interactionRepository = new DrugDrugInteractionApi();
    const getAllUseCase = new GetAllUseCase(interactionRepository);

    return getAllUseCase.getAllDrugDrugInteractions(token);
};

// Retorna una promesa para agregar una interaction droga-droga desde la API.
const _createDrugDrugInteraction = (interaction: DrugDrugInteraction, token: TokenPromise) => {
    const interactionRepository = new DrugDrugInteractionApi();
    const createUseCase = new CreateUseCase(interactionRepository);

    return createUseCase.createDrugDrugInteraction(interaction, token);
};

// Retorna una promesa para agregar un lote de interactiones droga-droga desde la API.
const _createDrugDrugInteractionBatch = (interactions: DrugDrugInteraction[], token: TokenPromise) => {
    const interactionRepository = new DrugDrugInteractionApi();
    const createBatchUseCase = new CreateBatchUseCase(interactionRepository);

    return createBatchUseCase.createDrugDrugInteractionBatch(interactions, token);
};

// Retorna una promesa para editar una interacción droga-droga desde la API.
const _editDrugDrugInteraction = (interactionId: number, interaction: DrugDrugInteraction, token: TokenPromise) => {
    const interactionRepository = new DrugDrugInteractionApi();
    const updateUseCase = new UpdateUseCase(interactionRepository);

    return updateUseCase.updateDrugDrugInteraction(interactionId, interaction, token);
};

// Retorna una promesa para eliminar desde la API una interacción droga-droga por su ID.
const _deleteDrugDrugInteraction = (interactionId: number, token: TokenPromise) => {
    const interactionRepository = new DrugDrugInteractionApi();
    const deleteUseCase = new DeleteUseCase(interactionRepository);

    return deleteUseCase.deleteDrugDrugInteraction(interactionId, token);
};

// Hook usado para gestionar el CRUD de interacciones droga-droga.
export const useDrugDrugInteractions = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<DrugDrugInteractionResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    const checkExists = useCallback(async (interaction: DrugDrugInteraction) => {
        const druID1 = interaction.drugId1 ?? null;
        const druID2 = interaction.drugId2 ?? null;
        const druID3 = interaction.drugId3 ?? null;
        const exists = data.find(i => i.drugId1 === druID1 && i.drugId2 === druID2 && i.drugId3 === druID3) !== undefined;
        await new Promise(r => setTimeout(r, 1000));
        if (exists) {
            isUpdateStateOk(new Error("Combinaciones de droga ya existe"));
            return true;
        } else {
            return false;
        }
    }, [data, isUpdateStateOk]);

    // Obtiene de la API todas las interacciones drogas-enfermedad registradas.
    const getAllDrugDrugInteractions = useCallback(() => {
        setState({ dataLoading: true, loading: false });
        _getAllDrugDrugInteractions(getTokens()).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, [getTokens]);

    // Guarda una interacción droga-droga desde API
    const saveDrugDrugInteraction = useCallback((interaction: DrugDrugInteractionResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        checkExists(interaction).then((exists) => {
            if (!exists) {
                _createDrugDrugInteraction(interaction, getTokens()).then(([res, error]) => {
                    if (isUpdateStateOk(error)) {
                        interaction.id = res?.data?.id ?? 0;
                        setData((prev) => ([...prev ?? [], interaction]));
                    }
                });
            }
        });
    }, [checkExists, getTokens, isUpdateStateOk]);

    // Guarda un lote de interacciones droga-droga desde API
    const saveDrugDrugInteractionBatch = useCallback((interactions: DrugDrugInteractionResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));

        _createDrugDrugInteractionBatch(interactions, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllDrugDrugInteractions();
            }
        });
    }, [getAllDrugDrugInteractions, getTokens, isUpdateStateOk]);

    // Edita una interacción droga-droga desde API
    const editDrugDrugInteraction = useCallback((interaction: DrugDrugInteractionResult) => {
        console.log(interaction);
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editDrugDrugInteraction(interaction.id, interaction, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === interaction.id ? interaction : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una interacción droga-droga del estado
    const deleteDrugDrugInteraction = useCallback((interactionId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDrugDrugInteraction(interactionId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== interactionId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    return { getAllDrugDrugInteractions, saveDrugDrugInteraction, saveDrugDrugInteractionBatch, editDrugDrugInteraction, deleteDrugDrugInteraction, state, data };
};