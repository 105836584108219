import { useCallback } from "react";

import { useLocation } from "wouter";

import { UserApi } from "data";
import TokenUpdateUseCase from "domain/use-cases/user/TokenUpdateUseCase";
import { useRefreshToken } from "presentation/utils/auth/hooks";
import JwtManager from "presentation/utils/auth/JwtManager";


// Actualiza el token.
const _refreshToken = (refreshToken: string) => {
    const userRepository = new UserApi();
    const tokenUpdateUseCase = new TokenUpdateUseCase(userRepository);

    return tokenUpdateUseCase.updateToken(refreshToken);
};

/// Hook usado para obtener o actualizar el token.
export const useToken = () => {
    // Estado del hook.
    const [, setLocation] = useLocation();
    const { getCurrentAuthState, getCurrentRefreshToken, updateAuthState, updateRefreshToken } = useRefreshToken();

    // Obtiene el token o lo actualiza
    const getTokens = useCallback(async (): Promise<[string | null, string | null]> => {
        const { authToken, expireAt } = getCurrentAuthState;
        const { refreshToken, refreshTokenExpireAt } = getCurrentRefreshToken;

        if (expireAt && expireAt > new Date()) {
            console.log("token aun no expira", expireAt);
            return [authToken, refreshToken];
        } else {
            if (refreshTokenExpireAt && refreshTokenExpireAt > new Date()) {
                console.log("token expiro pero refresh token no", refreshTokenExpireAt);
                const [res, error] = await _refreshToken(refreshToken ?? "");
                if (error === null && res !== null) {
                    if (res.data !== (null || undefined)) {
                        updateRefreshToken(res.data.refreshToken, JwtManager.getTokenExpInMinutes(res.data.refreshToken));
                        updateAuthState(res.data.accessToken, JwtManager.getTokenExpInMinutes(res.data.accessToken));
                    }
                }
                return [res?.data?.accessToken ?? null, res?.data?.refreshToken ?? null];
            } else {
                console.log("token expiro", refreshTokenExpireAt);
                setLocation("/login");
                return ["", ""];
            }

        }
    }, [getCurrentAuthState, getCurrentRefreshToken, setLocation, updateAuthState, updateRefreshToken]);

    return { getTokens };
};