import { useCallback, useState } from "react";

import { UserApi } from "data";
import ResetPasswordUseCase from "domain/use-cases/user/ResetPasswordUseCase";
import { IRequestResult } from "presentation/utils/interfaces/IRequestResult";
import { IUser } from "presentation/utils/interfaces/IUser";
import JwtManager from "presentation/utils/auth/JwtManager";

// Verifica usuario en la API y retorna las credenciales
const _resetPassword = (email: string) => {
    const userRepository = new UserApi();
    const resetPasswordUseCase = new ResetPasswordUseCase(userRepository);
    return resetPasswordUseCase.resetPassword(email);
};

/// Hook usado para recuperar contraseña
export const useResetPassword = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult<IUser>>({
        loading: false,
        error: undefined,
        data: undefined,
    });

    // Verificar email para cambiar contraseña
    const resetPassword = useCallback((email: string) => {
        setState({ loading: true});
        _resetPassword(email).then(([res, error]) => {
            setState({
                loading: false,
                error: error?.message,
                data: res && res?.data !== (null || undefined) ? {
                    token: res.data.accessToken,
                    refreshToken: res.data.refreshToken,
                    data: JwtManager.getUserTokenInformation(res.data.accessToken),
                } : undefined,
            });
        });
    }, []);

    return { resetPassword, state };
};