import { DrugClass, RequestBatchResult } from "domain/entities/drug-class";
import { DrugClassRepository } from "domain/repositories";

export default class CreateBatchUseCase {
    private drugClassRepository: DrugClassRepository;
    
    public constructor(drugClassRepository: DrugClassRepository) {
        this.drugClassRepository = drugClassRepository
    }

    public async createDrugClassBatch(drugClass: DrugClass[], token: Promise<[string | null, string | null]>): Promise<[RequestBatchResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugClassRepository.createDrugClassBatch(drugClass, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}