import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { DiseaseResult } from "domain/entities/disease";
import { DrugResult } from "domain/entities/drug/structures/DrugResult";
import { MethodResult } from "domain/entities/method";
import { SeverityResult } from "domain/entities/severity";
import { UserResult } from "domain/entities/user";

@JsonObject("DrugDiseaseInteractionResult")
export class DrugDiseaseInteractionResult {
    @JsonProperty("ddi_id", Number)
    id: number = 0;
    @JsonProperty("dru_id", Number)
    drugId: number = 0;
    @JsonProperty("Drug", DrugResult)
    drug?: DrugResult;
    @JsonProperty("dis_id", Number)
    diseaseId: number = 0;
    @JsonProperty("Disease", DiseaseResult)
    disease?: DiseaseResult;
    @JsonProperty("dse_id", Number)
    severityId: number = 0;
    @JsonProperty("DDISeverity", SeverityResult)
    severity?: SeverityResult;
    @JsonProperty("dty_id", Number)
    methodId: number = 0;
    @JsonProperty("DDIType", MethodResult)
    method?: MethodResult;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("ddi_effect", String)
    effect: string = "";
    @JsonProperty("ddi_description", String)
    description: string = "";
    @JsonProperty("ddi_evidence", String)
    evidence: string = "";
    @JsonProperty("ddi_evidence_source", String)
    evidenceSource: string = "";
    @JsonProperty("ddi_source", String)
    sources: string = "";
    @JsonProperty("ddi_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("ddi_updated_at", DateConverter)
    updatedAt: Date = new Date();
}