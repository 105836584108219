import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "domain/entities/decorators/DateConverter";
import { UserResult } from "domain/entities/user";

@JsonObject("DiseaseResult")
export class DiseaseResult {
    @JsonProperty("dis_id", Number)
    id: number = 0;
    @JsonProperty("usr_creator_id", Number, true)
    creatorId?: number;
    @JsonProperty("usr_creator", UserResult, true)
    creator?: UserResult;
    @JsonProperty("dis_name", String)
    name: string = "";
    @JsonProperty("dis_description", String)
    description: string = "";
    @JsonProperty("dis_sources", String)
    sources: string = "";
    @JsonProperty("dis_created_at", DateConverter)
    createdAt: Date = new Date();
    @JsonProperty("dis_updated_at", DateConverter)
    updatedAt: Date = new Date();
}