import React, { useMemo } from 'react';
import { AuthContext } from '../context/AuthProvider';

/**
 * Hook usado para obtener payload del usuario
 */
export const useAuthUser = () => {
    // Contexto de autenticación.
    const context = React.useContext(AuthContext);
    
    // Información del usuario.
    const authUser = useMemo(() => context.authState.payload, [context.authState.payload]);
    
    return authUser;
}