import { Result } from "domain/entities/result";
import { SeverityRepository } from "domain/repositories";

export default class DeleteUseCase {
    private severityRepository: SeverityRepository;
    
    public constructor(severityRepository: SeverityRepository) {
        this.severityRepository = severityRepository
    }

    public async deleteSeverity(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.severityRepository.deleteSeverity(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}