import { MethodResult, Method, RequestResult } from "domain/entities/method";
import { Result } from "domain/entities/result";
import { MethodRepository } from "domain/repositories";
import Base from "data/base";

export class MethodApi implements MethodRepository {
    
    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/interactions/methods";
    }

    /**
     * Obtiene todas los métodos
     * @param token
     * @returns retorna un array con los métodos
     */
    async getAllMethod(token: string): Promise<[MethodResult[], null] | [null, Error]> {
        try {
            const url: string = await MethodApi.rute() + "/";
            
            const [res, error] = await Base.getArray(url, MethodResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene un método por su ID
     * @param id 
     * @returns retorna método
     */
    async getMethod(id: number): Promise<[null, Error] | [MethodResult, null]> {
        try {
            const url: string = await MethodApi.rute() + "/" + id;
                
            const [res, error] = await Base.get(url, MethodResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un método
     * @param method 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createMethod(method: Method, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await MethodApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dty_name: method.name,
                dty_description: method.description,
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza un método
     * @param id 
     * @param method 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateMethod(id: number, method: Method, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await MethodApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dty_name: method.name,
                dty_description: method.description,
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina un método
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteMethod(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await MethodApi.rute() + "/" + id;
                
            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}