import React, { FunctionComponent, useMemo } from 'react';

import { Link, useLocation } from 'wouter';
import { Box, Flex, Heading, Image, Menu, MenuList, MenuButton, MenuDivider, MenuGroup, MenuItem, Text, Center } from '@chakra-ui/react';

import { IHeaderProps } from './IHeaderProps';
import { IUserData } from 'presentation/utils/interfaces/IUserData';
import { useSignOut, useIsAuthenticated, useAuthUser } from 'presentation/utils/auth/hooks';

import './Header.css';
import whiteLogo from './logo4.png';
import colorLogo from './logo4.png';
import { FaQuestionCircle } from 'react-icons/fa';
import { VideoModal } from '../VideoModal';

export const Header: FunctionComponent<IHeaderProps> = (props) => {

    const [show, setShow] = React.useState(false);
    const _handleToggle = () => setShow(!show);
    const [, setLocation] = useLocation();
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();
    const userInfo = useMemo(() => auth ? auth as { data: IUserData } : null, [auth]);
    const [tutorialState, setTutorialState] = React.useState({
        isOpen: false,
        title: "",
        url: "",
    })

    function urlActual() {
        var pathname = window.location.pathname;
        var pathname2 = window.location.pathname;
        var pathname3 = window.location.pathname;
        var pathname4 = window.location.pathname;
        if (pathname === "/profile/verified" || pathname2 === "/home" || pathname3 === "/login" || pathname4 === "/reset-password"){
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <Flex
                as="nav"
                align="center"
                wrap="wrap"
                justify="space-between"
                padding="0.5rem"
                color="white"
                bg={props.hasColor ? "white" : "transparent"}
            >

                {!isAuthenticated && 
                <Flex align="center" mr={5} ml={2} onClick={() => setLocation("/")} cursor="pointer">
                        <Image
                            src={props.hasColor ? colorLogo : whiteLogo}
                            height={props.hasColor ? "45px" : "60px"}
                        />
                    
                        <Box ml="10px">
                            <Heading as="h1" size="md" fontSize={props.hasColor ? "1.25rem" : "1.40rem"} letterSpacing={"-.1rem"}>
                                <span style={{ color: props.hasColor ? "#111346" : "white" }}>Multi</span>
                                <span style={{ color: props.hasColor ? "#ffc400" : "white" }}>drogas</span>
                            </Heading>
                            <Text
                                fontSize="xs"
                                letterSpacing="wide"
                                color={props.hasColor ? "black" : "white"}
                            >
                                Interacción entre fármacos
                            </Text>
                        </Box>
                    
                </Flex>
                }   
            
                {(isAuthenticated && urlActual() === true  ) && 
                <Flex align="center" mr={5} ml={2} onClick={() => signOut() && setLocation("/")} cursor="pointer">
                    <Image
                        src={props.hasColor ? colorLogo : whiteLogo}
                        height={props.hasColor ? "45px" : "60px"}
                    />
                    
                    <Box ml="10px">
                        <Heading as="h1" size="md" fontSize={props.hasColor ? "1.25rem" : "1.40rem"} letterSpacing={"-.1rem"}>
                            <span style={{ color: props.hasColor ? "#111346" : "white" }}>Multi</span>
                            <span style={{ color: props.hasColor ? "#ffc400" : "white" }}>drogas</span>
                        </Heading>
                        <Text
                            fontSize="xs"
                            letterSpacing="wide"
                            color={props.hasColor ? "black" : "white"}
                        >
                            Interacción entre fármacos
                        </Text>
                    </Box>
                    
                </Flex>
                }

                {(isAuthenticated && urlActual() === false ) && 
                <Flex align="center" mr={5} ml={2} onClick={() => setLocation("/")} cursor="pointer">
                    <Image
                        src={props.hasColor ? colorLogo : whiteLogo}
                        height={props.hasColor ? "45px" : "60px"}
                    />
                    
                    <Box ml="10px">
                        <Heading as="h1" size="md" fontSize={props.hasColor ? "1.25rem" : "1.40rem"} letterSpacing={"-.1rem"}>
                            <span style={{ color: props.hasColor ? "#111346" : "white" }}>Multi</span>
                            <span style={{ color: props.hasColor ? "#ffc400" : "white" }}>drogas</span>
                        </Heading>
                        <Text
                            fontSize="xs"
                            letterSpacing="wide"
                            color={props.hasColor ? "black" : "white"}
                        >
                            Interacción entre fármacos
                        </Text>
                    </Box>
                    
                </Flex>
                }
                    
                <Box display={{ base: "block", md: "none" }} mr={15} onClick={_handleToggle}>
                    <svg
                        fill="black"
                        width="12px"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </Box>

                <Box
                    display={{ base: "none", md: "block" }}
                    mt={{ base: 4, md: 0 }}
                    mr={15}
                    fontWeight="600"
                    color={props.hasColor ? "black" : "white"}
                >
                    {(isAuthenticated && urlActual() === false ) && 
                        <Menu>
                            <MenuButton>
                                <Box>
                                    <Flex>
                                        <Box bg="teal.400" pt="5px" color="white" borderRadius={50} boxSize="35px" overflow="hidden">
                                            {(userInfo?.data.usr_name.split(" ")[0] ?? "").charAt(0)}
                                            {(userInfo?.data.usr_name.split(" ")[1] ?? "").charAt(0)}
                                        </Box>
                                        <Box pt="5px" ml="5px">
                                            {(userInfo?.data.usr_name.split(" ")[0] ?? "") + " "}
                                            {(userInfo?.data.usr_name.split(" ")[1] ?? "")}
                                        </Box>
                                    </Flex>
                                </Box>
                            </MenuButton>
                            <MenuList color="black">
                                <MenuGroup title="Perfil">
                                    <MenuItem><Link to="/profile/me">Mi cuenta</Link></MenuItem>
                                </MenuGroup>
                                <MenuDivider />
                                <MenuGroup>
                                    <MenuItem><Link to="/home" onClick={() => signOut()}>Cerrar sesión</Link></MenuItem>
                                </MenuGroup>
                            </MenuList>
                        </Menu>
                    }
                    {(isAuthenticated && urlActual() === true) && 
                        <Flex>
                        <Center mr={5}>
                            <Menu>
                                <MenuButton>
                                    <FaQuestionCircle />
                                </MenuButton>
                                <MenuList color="black">
                                    <MenuGroup title="Preguntas Frecuentes">
                                        <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar el buscador?", url: "https://multidrogas.cl/videos/tutoriales/tutorialbuscador01.mp4" })}>¿Cómo usar el buscador?</MenuItem>
                                        <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar los filtros y más?", url: "https://multidrogas.cl/videos/tutoriales/tutorialElementos.mp4" })}>¿Cómo usar los filtros y más?</MenuItem>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>
                        </Center>
                        <Center>
                            <Link to="/login" onClick={() => signOut()}>Iniciar sesión</Link>
                        </Center>
                    </Flex>
                    }
                    {!isAuthenticated &&
                        <Flex>
                            <Center mr={5}>
                                <Menu>
                                    <MenuButton>
                                        <FaQuestionCircle />
                                    </MenuButton>
                                    <MenuList color="black">
                                        <MenuGroup title="Preguntas Frecuentes">
                                            <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar el buscador?", url: "https://multidrogas.cl/videos/tutoriales/tutorialbuscador01.mp4" })}>¿Cómo usar el buscador?</MenuItem>
                                            <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar los filtros y más?", url: "https://multidrogas.cl/videos/tutoriales/tutorialElementos.mp4" })}>¿Cómo usar los filtros y más?</MenuItem>
                                        </MenuGroup>
                                    </MenuList>
                                </Menu>
                            </Center>
                            <Center>
                                <Link to="/login">Iniciar sesión</Link>
                            </Center>
                        </Flex>
                    }
                </Box>

                <Box
                    display={{ base: show ? "block" : "none", md: "none" }}
                    mt={{ base: 4, md: 0 }}
                    mr={15}
                    fontWeight="600"
                    color={props.hasColor ? "black" : "white"}
                    width="100%"
                >
                     {(isAuthenticated && urlActual() === false) &&
                        <>
                            <Box mb="20px">
                                <Flex>
                                    <Box bg="teal.400" pl="5px" pt="5px" color="white" borderRadius={50} boxSize="35px" overflow="hidden">
                                        {(userInfo?.data.usr_name.split(" ")[0] ?? "").charAt(0)}
                                        {(userInfo?.data.usr_name.split(" ")[1] ?? "").charAt(0)}
                                    </Box>
                                    <Box pt="5px" ml="5px">
                                        {(userInfo?.data.usr_name.split(" ")[0] ?? "") + " "}
                                        {(userInfo?.data.usr_name.split(" ")[1] ?? "")}
                                    </Box>
                                </Flex>
                            </Box>
                            <Box ml="10px">
                                <Link to="/profile/me" >Mi cuenta</Link>
                            </Box>
                            <Box ml="10px">
                                <Link to="/home" onClick={() => signOut()}>Cerrar sesión</Link>
                            </Box>
                            

                        </>
                    }
                    {(isAuthenticated && urlActual() === true) &&
                        <Flex>
                        <Center mr={5}>
                            <Menu>
                                <MenuButton>
                                    <FaQuestionCircle />
                                </MenuButton>
                                <MenuList color="black">
                                    <MenuGroup title="Preguntas Frecuentes">
                                        <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar el buscador?", url: "https://multidrogas.cl/videos/tutoriales/tutorialbuscador01.mp4" })}>¿Cómo usar el buscador?</MenuItem>
                                        <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar los filtros y más?", url: "https://multidrogas.cl/videos/tutoriales/tutorialElementos.mp4" })}>¿Cómo usar los filtros y más?</MenuItem>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>
                        </Center>
                        <Center>
                            <Link to="/login" onClick={() => signOut()}>Iniciar sesión</Link>
                        </Center>           
                    </Flex>
                    }
                    {!isAuthenticated &&
                        <Flex>
                            <Center mr={5}>
                                <Menu>
                                    <MenuButton>
                                        <FaQuestionCircle />
                                    </MenuButton>
                                    <MenuList color="black">
                                        <MenuGroup title="Preguntas Frecuentes">
                                            <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar el buscador?", url: "https://multidrogas.cl/videos/tutoriales/tutorialbuscador01.mp4" })}>¿Cómo usar el buscador?</MenuItem>
                                            <MenuItem onClick={() => setTutorialState({ isOpen: true, title: "¿Cómo usar los filtros y más?", url: "https://multidrogas.cl/videos/tutoriales/tutorialElementos.mp4" })}>¿Cómo usar los filtros y más?</MenuItem>
                                        </MenuGroup>
                                    </MenuList>
                                </Menu>
                            </Center>
                            <Center>
                                <Link to="/login">Iniciar sesión</Link>
                            </Center>           
                        </Flex>
                        
                    }
                </Box>
            </Flex>
            <VideoModal
                title={tutorialState.title}
                isOpen={tutorialState.isOpen}
                onClose={() => setTutorialState(prev => ({ ...prev, isOpen: false }))}
                videoUrl={tutorialState.url}
            />
        </div>
    );
}