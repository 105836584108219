import { Result } from "domain/entities/result";
import { DrugClassRepository } from "domain/repositories";

export default class DeleteUseCase {
    private drugClassRepository: DrugClassRepository;
    
    public constructor(drugClassRepository: DrugClassRepository) {
        this.drugClassRepository = drugClassRepository
    }

    public async deleteDrugClass(id: number, token: Promise<[string | null, string | null]>): Promise<[Result, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugClassRepository.deleteDrugClass(id, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}