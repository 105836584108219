import React, { FunctionComponent, useLayoutEffect, useRef } from 'react';

import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { IInteractionChartProps } from './IInteractionChartProps';
import { useDrugInteraction } from "presentation/view-models/hooks";


am4core.useTheme(am4themes_kelly);
am4core.useTheme(am4themes_animated);

export const InteractionChart: FunctionComponent<IInteractionChartProps> = (props) => {
    const { getCount, count } = useDrugInteraction();
    const chart = useRef<any>(null);

    useLayoutEffect(() => {
        let x = am4core.create("chartdiv", am4plugins_wordCloud.WordCloud);
        let series = x.series.push(new am4plugins_wordCloud.WordCloudSeries());
        series.accuracy = 4;
        series.step = 15;
        series.rotationThreshold = 0.5;
        series.randomness = 0;
        series.maxCount = 200;
        series.minWordLength = 2;
        series.labels.template.tooltipText = "Interacciones de {tag} ({weight} puntos): \n Graves: {score1} \n Moderadas: {score2} \n Menores: {score3} ";
        series.labels.template.events.on("hit", (ev: any) => {
            props.onInteractionClick(ev.target.dataItem.dataContext.id);
        })
        //series.fontFamily = "Courier New";
        series.maxFontSize = am4core.percent(10);
        series.colors = new am4core.ColorSet();
        series.colors.passOptions = {};
        series.colors.reuse = true;

        series.paddingTop = 0;
        series.marginTop = 0;

        getCount();

        series.data = [];
        series.dataFields.word = "tag";
        series.dataFields.value = "weight";

        chart.current = x;

        return () => {
            x.dispose();
        };
    }, [getCount, props]);

    useLayoutEffect(() => {
        chart.current.data = count.data?.map(i => {
            return {
                id: i.drugId,
                tag: i.drugName,
                weight: i.severityScore1 * 3 + i.severityScore2 * 2 + i.severityScore3 * 1,
                score1: i.severityScore1,
                score2: i.severityScore2,
                score3: i.severityScore3,
            };
        });
    }, [count.data]);

    return (
        <Box margin="5px">
            <Center>
                <Heading as="h4" size="md" textAlign="center">Número de interacciones con otras drogas</Heading>
            </Center>
            <Center>
                <Text fontSize="sm" color="#a3a3a3">
                    Interacciones graves 3 puntos; interacciones moderadas 2 puntos; interacciones menores 1 punto.
                </Text>
            </Center>
            <Flex id="chartdiv" style={{ width: "100%", height: "500px" }}></Flex>
        </Box>
    );
}