import { MethodResult } from "domain/entities/method";
import { MethodRepository } from "domain/repositories";

export default class GetAllUseCase {
    private methodRepository: MethodRepository;
    
    public constructor(methodRepository: MethodRepository) {
        this.methodRepository = methodRepository
    }

    public async getAllMethods(token: Promise<[string | null, string | null]>): Promise<[MethodResult[], null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.methodRepository.getAllMethod(t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}