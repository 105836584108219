import { useCallback, useState } from "react";

import { GridModalMode } from "presentation/components/GridModal/IGridModalProps";
import { useDisclosure } from "@chakra-ui/react";

/// Hook usado para gestionar el modal de la grilla
export const useGridModalManager = <T>() => {
    // Estado del hook.
    const [elementEdit, setElementEdit] = useState<T>();
    const [modalMode, setModalMode] = useState<GridModalMode>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Vuelve los estados a indefinido
    const closeModal = useCallback(() => {
        onClose();
        setModalMode(undefined);
        setElementEdit(undefined);
    }, [onClose]);

    // Abre modal en modo creación
    const openCreateMode = useCallback(() => {
        setModalMode(GridModalMode.CREATE);
        onOpen();
    }, [onOpen]);

    // Abre modal en modo edición
    const openEditMode = useCallback((element: T) => {
        setModalMode(GridModalMode.EDIT);
        setElementEdit(element);
        onOpen();
    }, [onOpen]);

    return { closeModal, openCreateMode, openEditMode, modalMode, elementEdit, isOpen };
};