import { CommentResult, Comment, RequestResult } from "domain/entities/comment";
import { Result } from "domain/entities/result";
import { CommentRepository } from "domain/repositories";
import Base from "data/base";

export class CommentApi implements CommentRepository {

    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/comments";
    }

    /**
     * Obtiene todos los comentarios
     * @returns retorna un array con los comentarios
     */
    async getAllComments(): Promise<[CommentResult[], null] | [null, Error]> {
        try {
            const url: string = await CommentApi.rute() + "/";

            const [res, error] = await Base.getArray(url, CommentResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene un comentario por su ID
     * @param id 
     * @returns retorna comentario
     */
    async getComment(id: number): Promise<[null, Error] | [CommentResult, null]> {
        try {
            const url: string = await CommentApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, CommentResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea un comentario
     * @param comment 
     * @param token
     * @returns Retorna el resultado de la creación
     */
    async createComment(comment: Comment, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await CommentApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                com_name: comment.name,
                com_email: comment.email,
                com_description: comment.description,
                com_site: comment.site
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina un comentario
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteComment(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await CommentApi.rute() + "/" + id;

            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}