import React, { FunctionComponent } from 'react';

import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { v4 as uuid_v4 } from 'uuid';

import { ISideEffectListProps } from './ISideEffectListProps';


export const SideEffectList: FunctionComponent<ISideEffectListProps> = (props) => {

    function _getSideEffects(effectList: string[]): any {
        return effectList.map((link: string) => {
            return <ListItem key={uuid_v4()}>{link}</ListItem>;
        });
    }

    return (
        <>
            { props.sideEffects.length > 0 && props.sideEffects[0] !== null &&
                <Box marginTop="5px" marginBottom="5px" maxWidth="100%">
                    <Text
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize="sm"
                        letterSpacing="wide"
                        color="gray.600"
                    >
                        Efectos secundarios
                    </Text>
                    <UnorderedList>{_getSideEffects(props.sideEffects)}</UnorderedList>
                </Box>
            }
        </>
    );
}