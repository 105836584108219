import { useCallback, useState } from "react";

import { DrugClassApi } from "data";
import { DrugClass, DrugClassResult } from "domain/entities/drug-class";
import GetAllUseCase from "domain/use-cases/drug-class/GetAllUseCase";
import CreateUseCase from "domain/use-cases/drug-class/CreateUseCase";
import CreateBatchUseCase from "domain/use-cases/drug-class/CreateBatchUseCase";
import UpdateUseCase from "domain/use-cases/drug-class/UpdateUseCase";
import DeleteUseCase from "domain/use-cases/drug-class/DeleteUseCase";
import { useToken } from "presentation/view-models/hooks";
import { TokenPromise } from "presentation/utils/interfaces/types";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";

// Retorna una promesa para obtener de la API todos los alimentos registrados.
const _getAllDrugClasses = () => {
    const drugClassRepository = new DrugClassApi();
    const getAllUseCase = new GetAllUseCase(drugClassRepository);

    return getAllUseCase.getAllDrugClasses();
};

// Retorna una promesa para agregar una clase de droga desde la API.
const _createDrugClass = (drugClass: DrugClass, token: TokenPromise) => {
    const drugClassRepository = new DrugClassApi();
    const createUseCase = new CreateUseCase(drugClassRepository);

    return createUseCase.createDrugClass(drugClass, token);
};

// Retorna una promesa para agregar un lote de clases de droga desde la API.
const _createDrugClassBatch = (drugClasses: DrugClass[], token: TokenPromise) => {
    const drugClassRepository = new DrugClassApi();
    const createBatchUseCase = new CreateBatchUseCase(drugClassRepository);

    return createBatchUseCase.createDrugClassBatch(drugClasses, token);
};

// Retorna una promesa para editar una clase de droga desde la API.
const _editDrugClass = (drugClassId: number, drugClass: DrugClass, token: TokenPromise) => {
    const drugClassRepository = new DrugClassApi();
    const updateUseCase = new UpdateUseCase(drugClassRepository);

    return updateUseCase.updateDrugClass(drugClassId, drugClass, token);
};

// Retorna una promesa para eliminar desde la API una clase de droga por su ID.
const _deleteDrugClass = (drugClassId: number, token: TokenPromise) => {
    const drugClassRepository = new DrugClassApi();
    const deleteUseCase = new DeleteUseCase(drugClassRepository);

    return deleteUseCase.deleteDrugClass(drugClassId, token);
};

// Hook usado para gestionar el CRUD de clases de droga.
export const useDrugClass = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<DrugClassResult[]>([]);
    const { getTokens } = useToken();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todas las clases de droga registradas.
    const getAllDrugClasses = useCallback(() => {
        setState({ dataLoading: true, loading: false});
        _getAllDrugClasses().then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? []);
        });
    }, []);

    // Guarda una clase de droga desde API
    const saveDrugClass = useCallback((drugClass: DrugClassResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDrugClass(drugClass, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                drugClass.id = res?.data?.id ?? 0;
                setData((prev) => ([...prev ?? [], drugClass]));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Guarda un lote de clases de droga desde API
    const saveDrugClassBatch = useCallback((drugClasses: DrugClassResult[]) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createDrugClassBatch(drugClasses, getTokens()).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                getAllDrugClasses();
            }
        });
    }, [getAllDrugClasses, getTokens, isUpdateStateOk]);

    // Edita una clase de droga desde API
    const editDrugClass = useCallback((drugClass: DrugClassResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.PUT, loading: true, reqCompleted: false }));
        _editDrugClass(drugClass.id, drugClass, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.map(item => item.id === drugClass.id ? drugClass : item)));
            }
        });
    }, [getTokens, isUpdateStateOk]);

    // Elimina una clase de droga del estado
    const deleteDrugClass = useCallback((foodId: number) => {
        setState((prev) => ({ ...prev, reqType: RequestType.DELETE, loading: true, reqCompleted: false }));
        _deleteDrugClass(foodId, getTokens()).then(([_, error]) => {
            if (isUpdateStateOk(error)) {
                setData((prev) => (prev?.filter(item => item.id !== foodId)));
            }
        });
    }, [getTokens, isUpdateStateOk]);


    return { getAllDrugClasses, saveDrugClass, saveDrugClassBatch, editDrugClass, deleteDrugClass, state, data };
};