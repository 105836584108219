import React, { useCallback } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { ISignInProps } from '../types';

/**
 * Hook usado para gestionar token al iniciar sesión
 */
export const useSignIn = () => {
    // Contexto de autenticación.
    const context = React.useContext(AuthContext);

    // Guarda el token y su información
    const signIn = useCallback((signInConfig: ISignInProps): boolean => {
        const { token, payload, expiresIn, refreshToken, refreshTokenExpireIn } = signInConfig;
        if ((refreshToken || refreshTokenExpireIn) && !context.authState.isUsingRefreshToken) {
            throw new Error('Refresh token no implementado');
        }

        const expTime = new Date(new Date().getTime() + expiresIn * 60 * 1000);
        const refreshTokenExpireAt = !!refreshTokenExpireIn ? new Date(new Date().getTime() + refreshTokenExpireIn * 60 * 1000) : null;

        try {
            if (context) {
                context.setAuthState((prev) => ({
                    ...prev,
                    authToken: token,
                    expireAt: expTime,
                    payload: payload,
                    refreshToken: !!refreshToken ? refreshToken : null,
                    refreshTokenExpireAt: refreshTokenExpireAt,
                }));
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }, [context]);

    return signIn;
};