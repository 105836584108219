import { IToken, ITokenInfo, StrOrNull } from "./types";

/**
 * Jwt manager
 * Almacena y recupera token desde localstorage.
 */
export default class JwtManager {
    /** Nombre del almacenamiento del token en el localstorage */
    private readonly authStorageName: string;
    /** Nombre del almacenamiento de payload del token en el localstorage */
    private readonly payloadStorageName: string;
    /** Nombre del almacenamiento de la fecha de expiración del token en el localstorage */
    private readonly authTimeStorageName: string;
    /** Nombre del almacenamiento del refresh token en el localstorage */
    private readonly refreshTokenName: string | undefined;
    /** Nombre del almacenamiento de la fecha de expiración refresh token en el localstorage */
    private readonly refreshTokenTimeName: string | null;
    /** Uso de refresh token */
    private readonly isUsingRefreshToken: boolean;

    /**
     * Crea una instacia de JwtManager
     * @param authStorageName Nombre de almacenamiento de token en localstorage
     * @param authTimeStorageName Nombre del almacenamiento de la fecha de expiración del token en el localstorage
     * @param payloadStorageName Nombre del almacenamiento de payload del token en el localstorage
     * @param [refreshTokenName] Nombre del almacenamiento de la fecha de expiración de refresh token en el localstorage
     */
    constructor(authStorageName: string, authTimeStorageName: string, payloadStorageName: string, refreshTokenName?: string) {
        this.authStorageName = authStorageName;
        this.authTimeStorageName = authTimeStorageName;
        this.payloadStorageName = payloadStorageName;
        this.refreshTokenName = refreshTokenName;
        this.isUsingRefreshToken = !!this.refreshTokenName;
        this.refreshTokenTimeName = this.refreshTokenName ? `${this.refreshTokenName}_time` : null;
    }

    /**
     * Obtiene el el token inicial y el payload
     * @returns token 
     */
    public initialToken(): IToken {
        const authToken = localStorage.getItem(this.authStorageName);
        const authTokenTime = localStorage.getItem(this.authTimeStorageName);
        const payloadToken = localStorage.getItem(this.payloadStorageName);

        const refreshToken = this.isUsingRefreshToken &&
            this.refreshTokenName != null ?
            localStorage.getItem(this.refreshTokenName) : null;

        const refreshTokenTime = this.isUsingRefreshToken &&
            this.refreshTokenTimeName != null ?
            localStorage.getItem(this.refreshTokenTimeName) : null;

        return this.checkTokenExist(
            authToken,
            authTokenTime,
            payloadToken,
            refreshToken,
            refreshTokenTime,
        );
    }

    /**
     * Comprueba si el token existe
     * @param authToken Token
     * @param authTokenTime Fecha de expiración del token
     * @param stateToken Payload del token
     * @param refreshToken Refresh token
     * @param refreshTokenTime Fecha de expiración del refresh token
     * @returns token exist 
     */
    private checkTokenExist(authToken: StrOrNull, authTokenTime: StrOrNull, stateToken: StrOrNull, refreshToken: StrOrNull, refreshTokenTime: StrOrNull): IToken {
        if (!!authToken && !!authTokenTime && !!stateToken) {
            const expiresAt = new Date(authTokenTime);
            try {
                const authState = JSON.parse(stateToken);

                return {
                    authToken: authToken,
                    isUsingRefreshToken: this.isUsingRefreshToken,
                    refreshToken: this.isUsingRefreshToken && !!refreshToken ? refreshToken : null,
                    refreshTokenExpireAt: this.isUsingRefreshToken && !!refreshTokenTime ? new Date(refreshTokenTime) : null,
                    expireAt: expiresAt,
                    payload: authState,
                };
            } catch (e) { }
        }

        return {
            authToken: null,
            isUsingRefreshToken: this.isUsingRefreshToken,
            refreshToken: null,
            expireAt: null,
            payload: null,
            refreshTokenExpireAt: null,
        };
    }

    /**
     * Sincroniza el token
     * @param authState Token
     */
    public syncTokens(authState: IToken) {
        if (authState.authToken === undefined || authState.authToken === null ||
            authState.expireAt === null || authState.payload === null
        ) {
            this.removeToken();
        } else {
            this.setToken(
                authState.authToken,
                authState.expireAt,
                authState.refreshToken,
                authState.refreshTokenExpireAt,
                authState.payload,
            );
        }
    }

    /**
     * Establece token
     * @param authToken Token
     * @param expiresAt Fecha de expiración
     * @param refreshToken Refresh token
     * @param refreshTokenExpiresAt Fecha de expiración
     * @param payload Payload del token
     */
    public setToken(authToken: string, expiresAt: Date, refreshToken: string | null, refreshTokenExpiresAt: Date | null, payload: object) {
        localStorage.setItem(this.authStorageName, authToken);
        localStorage.setItem(this.authTimeStorageName, expiresAt.toISOString());
        localStorage.setItem(this.payloadStorageName, JSON.stringify(payload));
        if (this.isUsingRefreshToken && !!this.refreshTokenName && !!refreshToken) {
            localStorage.setItem(this.refreshTokenName, refreshToken);
        }
        if (this.isUsingRefreshToken && !!this.refreshTokenTimeName && !!refreshTokenExpiresAt) {
            localStorage.setItem(this.refreshTokenTimeName, refreshTokenExpiresAt.toISOString());
        }
    }

    /**
     * Elimina el token
     */
    public removeToken() {
        localStorage.removeItem(this.authStorageName);
        localStorage.removeItem(this.authTimeStorageName);
        localStorage.removeItem(this.payloadStorageName);
        if (this.isUsingRefreshToken && !!this.refreshTokenName) {
            localStorage.removeItem(this.refreshTokenName);
        }
        if (this.isUsingRefreshToken && !!this.refreshTokenTimeName) {
            localStorage.removeItem(this.refreshTokenTimeName);
        }
    }


    /**
     * Obtiene la información del token.
     * @param token 
     * @returns Información del token
     */
    static getUserTokenInformation(token: string): (ITokenInfo | null) {
        try {
            const jwt = this.jwtDecode(token);
            if (jwt && jwt.usr_id !== undefined && jwt.usr_name !== undefined && jwt.usr_perms !== undefined) {
                const permissions = (jwt.usr_perms as string).split("-").map(p => ({
                    module: p.split("_")[0],
                    permissions: p.split("_")[1],
                }));
                return {
                    usr_id: jwt.usr_id,
                    usr_name: jwt.usr_name,
                    usr_permissions: permissions,
                }
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    /**
     * Obtiene la expiración del token en minutos.
     * @param token 
     * @returns Expiración del token en minutos
     */
    static getTokenExpInMinutes(token: string): number {
        const exp = this.jwtExp(token);
        if (exp) {
            const time = Date.now();
            const dif = (exp - time) / 60000;
            return dif >= 0 ? dif : 0;
        } else {
            return 0;
        }
    }

    /// Decodifica un token dado
    private static jwtDecode(token?: any): any | null {
        if (!(typeof token === 'string')) {
            return null;
        }

        const split = token.split('.');

        if (split.length < 2) {
            return null;
        }

        try {
            const jwt = JSON.parse(atob(token.split('.')[1]));
            return jwt;
        } catch (e) {
            return null;
        }
    }

    /// Obtiene la expiración de un token en milisegundos
    private static jwtExp(token?: any): number | null {
        try {
            const jwt = this.jwtDecode(token);
            if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
                return jwt.exp * 1000;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }
}