import { Food, RequestBatchResult } from "domain/entities/food";
import { FoodRepository } from "domain/repositories";

export default class CreateBatchUseCase {
    private foodRepository: FoodRepository;
    
    public constructor(foodRepository: FoodRepository) {
        this.foodRepository = foodRepository
    }

    public async createFoodBatch(food: Food[], token: Promise<[string | null, string | null]>): Promise<[RequestBatchResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.foodRepository.createFoodBatch(food, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}