import React, { FormEvent, FunctionComponent, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import { Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, useToast } from '@chakra-ui/react';

import { IEditModalProps } from './IEditModalProps';
import { DeleteAlertDialog } from 'presentation/components/DeleteAlertDialog';

export const EditModal: FunctionComponent<IEditModalProps> = (props) => {

    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);
    const onCloseDeleteDialog = () => setIsOpenDeleteDialog(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    async function onDeleteElement() {
        if (props.onDeleteElement) {
            const [res, error] = await props.onDeleteElement();
            if (error === null && res !== null && res.status === 200) {
                toast({
                    title: "Elemento eliminado.",
                    description: "El elemento fue eliminado con exito!",
                    status: "info",
                    duration: 9000,
                    isClosable: true,
                });
                onCloseDeleteDialog();
                if (props.onSuccessDelete) props.onSuccessDelete(res);
            } else {
                toast({
                    title: "Ocurrió un error",
                    description: "El elemento no pudo ser eliminado. " + error?.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                onCloseDeleteDialog();
            }
        }
    }

    async function onSaveElement() {
        if (props.onSave === undefined || props.onSuccessSave === undefined) return;
        setIsLoading(true);
        const [res, error] = await props.onSave();

        if (error === null && res !== null && (res.status === 200 || res.status === 201)) {
            toast({
                title: "Elemento guardado.",
                description: "El elemento fue guardado con exito!",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setIsLoading(false);
            props.onSuccessSave(res);
        } else {
            toast({
                title: "Ocurrió un error",
                description: "El elemento no pudo ser guardado. " + error?.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            setIsLoading(false);
        }
    }

    function _handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        onSaveElement();
    }

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={props.isOpen}
                onClose={props.onClose}
                scrollBehavior="inside"
                size="lg"
            >
                <ModalOverlay />
                <form onSubmit={_handleSubmit}>
                <ModalContent>
                        <ModalHeader>{props.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {props.children}
                        </ModalBody>
                        <ModalFooter>
                            {!props.createMode &&
                                <>
                                    <IconButton
                                        colorScheme="red"
                                        icon={<FiTrash2 />}
                                        aria-label="Eliminar elemento"
                                        onClick={() => { setIsOpenDeleteDialog(true) }}
                                        disabled={isLoading}
                                    />
                                    <Spacer />
                                </>
                            }
                            <Button
                                id="btn-enviar-info"
                                type="submit"
                                colorScheme="blue"
                                mr={3}
                                loadingText="Guardando..."
                                isLoading={isLoading}
                                disabled={props.onSave === undefined}
                            >
                                Guardar
                        </Button>
                            <Button
                                onClick={props.onClose}
                                disabled={isLoading}
                            >
                                {props.onSave === undefined ? "Cerrar" : "Cancelar"}
                        </Button>
                        </ModalFooter>
                </ModalContent>
                </form>
            </Modal>
            { isOpenDeleteDialog &&
                <DeleteAlertDialog
                    isOpen={isOpenDeleteDialog}
                    onDelete={onDeleteElement}
                    onCancel={onCloseDeleteDialog}
                />
            }
        </>
    );
}