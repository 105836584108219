import React, { lazy, Suspense, useEffect } from 'react';

import { ChakraProvider, extendTheme, Progress } from '@chakra-ui/react';
import { Route, Switch, useLocation } from 'wouter';

import { Header, PrivateRoute } from 'presentation/components';
import { DefaultRoute } from 'presentation/components/DefaultRoute';
import AuthProvider from 'presentation/utils/auth/context/AuthProvider';

import { ModuleName } from 'presentation/utils/functions/getPermission';

const HomePage = lazy(() => import('presentation/views/Home/HomePage'));
const LoginPage = lazy(() => import('presentation/views/Login/LoginPage'));
const AdminPage = lazy(() => import('presentation/views/AdminPage/AdminHomePage'));
const MePage = lazy(() => import('presentation/views/MePage'));
const InteractionResultPage = lazy(() => import('presentation/views/InteractionResult/InteractionResultPage'));
const UserMgmtPage = lazy(() => import('presentation/views/AdminPage/Administration/UserMgmtPage'));
const DrugMgmtPage = lazy(() => import('presentation/views/AdminPage/Elements/DrugMgmtPage'));
const DiseaseMgmtPage = lazy(() => import('presentation/views/AdminPage/Elements/DiseaseMgmtPage'));
const FoodMgmtPage = lazy(() => import('presentation/views/AdminPage/Elements/FoodMgmtPage'));
const DrugDrugInteractionMgmtPage = lazy(() => import('presentation/views/AdminPage/Interactions/DrugDrugInteractionMgmtPage'));
const DrugDiseaseInteractionMgmtPage = lazy(() => import('presentation/views/AdminPage/Interactions/DrugDiseaseInteractionMgmtPage'));
const DrugFoodInteractionMgmtPage = lazy(() => import('presentation/views/AdminPage/Interactions/DrugFoodInteractionMgmtPage'));
const DrugClassMgmtPage = lazy(() => import('presentation/views/AdminPage/Elements/DrugClassMgmtPage'));
const CommentMgmtPage = lazy(() => import ('presentation/views/AdminPage/Administration/CommentMgmtPage'));
const ResetPasswordPage = lazy(() => import ('presentation/views/ResetPassword/ResetPasswordPage')); 
const VerifiedPage = lazy(() => import('presentation/views/ResetPassword/VerifiedPage'));

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "#f7f7f7",
        color: "black",
      },
    },
  },
})

export const App = () => {

  useEffect(() => {
    document.title = "Multidroga"
  }, []);

  const [location] = useLocation();

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider
        authStorageName="jwt"
        authTimeStorageName="_auth_time"
        stateStorageName="_auth_state"
        refreshTokenName="refresh-jwt"
      >
        {location !== "/home" &&
          <Header hasColor={true} />
        }
        <Suspense fallback={<Progress size="xs" isIndeterminate />}>
          <Switch>
            <Route
              path="/home"
              component={HomePage}
            />
            <Route
              path="/login"
              component={LoginPage}
            />
            <Route
              path="/interactions/result"
              component={InteractionResultPage}
            />
            <Route
              path="/reset-password"
              component={ResetPasswordPage}
            />
            <PrivateRoute
              path="/profile/verified"
              component={VerifiedPage}
            />
            <PrivateRoute
              path="/admin/home"
              component={AdminPage}
            />
            <PrivateRoute
              path="/admin/admin/users"
              module={ModuleName.Usr}
              component={UserMgmtPage}
            />
            <PrivateRoute
              path="/admin/admin/comments"
              module={ModuleName.Com}
              component={CommentMgmtPage}
            />
            <PrivateRoute
              path="/admin/elements/drugs/classes"
              module={ModuleName.Dcl}
              component={DrugClassMgmtPage}
            />
            <PrivateRoute
              path="/admin/elements/drugs"
              module={ModuleName.Dru}
              component={DrugMgmtPage}
            />
            <PrivateRoute
              path="/admin/elements/diseases"
              module={ModuleName.Dis}
              component={DiseaseMgmtPage}
            />
            <PrivateRoute
              path="/admin/elements/foods"
              module={ModuleName.Foo}
              component={FoodMgmtPage}
            />
            <PrivateRoute
              path="/admin/interactions/drug-drug"
              module={ModuleName.Din}
              component={DrugDrugInteractionMgmtPage}
            />
            <PrivateRoute
              path="/admin/interactions/drug-disease"
              module={ModuleName.Ddi}
              component={DrugDiseaseInteractionMgmtPage}
            />
            <PrivateRoute
              path="/admin/interactions/drug-food"
              module={ModuleName.Dfi}
              component={DrugFoodInteractionMgmtPage}
            />
            <PrivateRoute
              path="/profile/me"
              component={MePage}
            />
            <DefaultRoute
              defaultPath="/home"
              adminPath="/admin/home"
            />
          </Switch>
        </Suspense>
      </AuthProvider>
    </ChakraProvider>
  )
};
