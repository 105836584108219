export enum ModuleName {
    Dcl = "DCL",
    Dru = "DRU",
    Dis = "DIS",
    Foo = "FOO",
    Din = "DIN",
    Ddi = "DDI",
    Dfi = "DFI",
    Usr = "USR",
    Rol = "ROL",
    Com = "COM",
}

export enum PermissionType {
    Create = "C",
    Read = "R",
    Update = "U",
    Delete = "D",
}

const getPermission = (module: ModuleName, permissions?: {
    module: string,
    permissions: string,
}[], permissionType?: PermissionType) => {
    const permission = permissions?.find(p => p.module === module);
    if (permission) {
        if (permissionType) {
            return permission.permissions.includes(permissionType);
        } else {
            return permission.permissions !== undefined;
        }
    }
    return false;
}

export default getPermission;