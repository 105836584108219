export enum GridModalMode {
    CREATE,
    EDIT,
}

export interface IGridModalProps {
    title: string;
    isOpen: boolean;
    isLoading: boolean;
    mode: GridModalMode;
    deleteDisabled?: boolean;
    onClose: () => void;
    onSave?: () => void;
    onEdit?: () => void;
    onDelete?: () => void;
    onValidation?: () => boolean;
}