import { SeverityResult, Severity, RequestResult } from "domain/entities/severity";
import { Result } from "domain/entities/result";
import { SeverityRepository } from "domain/repositories";
import Base from "data/base";

export class SeverityApi implements SeverityRepository {
    
    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.DDI_API_URL + "/api/v1/interactions/severities";
    }

    /**
     * Obtiene todas las severidades
     * @param token
     * @returns retorna un array con las severidades
     */
    async getAllSeverities(token: string): Promise<[SeverityResult[], null] | [null, Error]> {
        try {
            const url: string = await SeverityApi.rute() + "/";
            
            const [res, error] = await Base.getArray(url, SeverityResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]

        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Obtiene una severidad por su ID
     * @param id 
     * @returns retorna severidad
     */
    async getSeverity(id: number): Promise<[null, Error] | [SeverityResult, null]> {
        try {
            const url: string = await SeverityApi.rute() + "/" + id;
                
            const [res, error] = await Base.get(url, SeverityResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Crea una severidad
     * @param severity 
     * @param token 
     * @returns Retorna el resultado de la creación
     */
    async createSeverity(severity: Severity, token: string): Promise<[null, Error] | [RequestResult, null]> {
        try {
            const url: string = await SeverityApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                dse_name: severity.name,
                dse_description: severity.description,
            }, RequestResult, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Actualiza una severidad
     * @param id 
     * @param severity 
     * @param token 
     * @returns Retorna el resultado de la actualización 
     */
    async updateSeverity(id: number, severity: Severity, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await SeverityApi.rute() + "/" + id;
            const [res, error] = await Base.put(url, {
                dse_name: severity.name,
                dse_description: severity.description,
            }, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    /**
     * Elimina una severidad
     * @param id 
     * @param token 
     * @returns Retorna el resultado de la eliminación 
     */
    async deleteSeverity(id: number, token: string): Promise<[null, Error] | [Result, null]> {
        try {
            const url: string = await SeverityApi.rute() + "/" + id;
                
            const [res, error] = await Base.delete(url, Result, token);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}