import React, { useCallback, useMemo } from 'react';
import { AuthContext } from '../context/AuthProvider';

/**
 * Hook usado para gestionar token
 */
export const useRefreshToken = () => {
    const context = React.useContext(AuthContext);

    // Obtiene el refresh token actual
    const getCurrentRefreshToken = useMemo(() => {
        return {
            refreshToken: context.authState.refreshToken,
            refreshTokenExpireAt: context.authState.refreshTokenExpireAt,
        };
    }, [context.authState.refreshToken, context.authState.refreshTokenExpireAt]);

    // Obtiene el estado actual de la autentificación
    const getCurrentAuthState = useMemo(() => {
        return {
            authToken: context.authState.authToken,
            expireAt: context.authState.expireAt,
        };
    }, [context.authState.authToken, context.authState.expireAt]);

    // Obtiene el payload actual
    const getCurrentPayload = useMemo(() => {
        return context.authState.payload;
    }, [context.authState.payload]);

    // Actualiza el refresh token actual
    const updateRefreshToken = useCallback((refreshToken: string, expiresIn: number) => {
        const expireAt = new Date(new Date().getTime() + expiresIn * 60 * 1000);
        context.setAuthState((prevState) => ({
            ...prevState,
            refreshToken: refreshToken,
            refreshTokenExpireAt: expireAt,
        }));
    }, [context]);
    
    // Actualiza el estado de la autenticación
    const updateAuthState = useCallback((authToken: string, expiresIn?: number) => {
        const o = { authToken: authToken };

        if (expiresIn !== undefined) {
            const expireAt = new Date(new Date().getTime() + expiresIn * 60 * 1000);
            Object.assign(o, { expireAt: expireAt });
        }

        context.setAuthState((prevState) => ({ ...prevState, ...o }));
    }, [context]);

    // Updates the Auth User's state
    const updateUserState = useCallback((userState: object) => {
        context.setAuthState((prevState) => ({ ...prevState, authState: userState }));
    }, [context]);

    return { updateRefreshToken, updateAuthState, updateUserState, getCurrentRefreshToken, getCurrentAuthState, getCurrentPayload };
}