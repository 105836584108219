import { LoginResult } from "domain/entities/user";
import { UserRepository } from "domain/repositories";

export default class LoginUseCase {
    private userRepository: UserRepository;
    
    public constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    public async login(email: string, password: string): Promise<([LoginResult, null]) | ([null, Error])> {
        return this.userRepository.login(email, password);
    }
}