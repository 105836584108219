import { DrugDiseaseInteraction, RequestBatchResult } from "domain/entities/drug-disease-interaction";
import { DrugDiseaseInteractionRepository } from "domain/repositories";

export default class CreateBatchUseCase {
    private drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository;
    
    public constructor(drugDiseaseInteractionRepository: DrugDiseaseInteractionRepository) {
        this.drugDiseaseInteractionRepository = drugDiseaseInteractionRepository
    }

    public async createDrugDiseaseInteractionBatch(drugDiseaseInteraction: DrugDiseaseInteraction[], token: Promise<[string | null, string | null]>): Promise<[RequestBatchResult, null] | [null, Error]> {
        const [t, ] = await token;
        if (t !== null) {
            return this.drugDiseaseInteractionRepository.createDrugDiseaseInteractionBatch(drugDiseaseInteraction, t);
        } else {
            return [null, new Error("Autenticación faltante")];
        }
    }
}